.regionen-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	.img-thumbnail {
		padding: 0;
		border: 0;
		border-radius: 0;
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	.mb-15 {
		margin-bottom: 1.5rem;
	}

	.col-md-6 {
		@media (max-width: 767px) {
			margin-bottom: 1.5rem;
		}
	}


	.hero-subtitle {
		font-family: var(--hero-header-sub-title-font-family);
		font-size: 22px;
		margin-bottom: 20px;
		position: relative;
		padding-bottom: 15px;

		&:before {
			content: "";
			position: absolute;
			bottom: 11px;
			left: 50%;
			transform: translateX(-50%);
			width: 15%;
			height: 1px;
			background-color: #2dace3;
			background-color: var(--color-primary);
			bottom: 0;
		}
	}
}