.similar-units-v1 {


	.similar-units-tabs {
		margin-bottom: 10px;
		margin-left: 14px;

		.tab {

			display: inline-block;
			padding: 3px 15px;
			background-color: var(--color-white);
			color: var(--color-black);
			cursor: pointer;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border: 1px solid #000;

			&.active {
				color: var(--color-white);
				border: 1px solid var(--color-primary);
			}

			@media(max-width: 560px) {
				font-size: var(--font-size-md);
				padding: 3px 10px;
			}

			&.active {
				background-color: var(--color-primary);
			}
		}

		.desktop {
			display: none;

			@media(min-width: 561px) {
				display: block;
			}
		}

		.mobile {
			display: none;


			@media(max-width: 560px) {
				display: block;
			}
		}
	}

	.similar-units {

		position: relative;
		height: 430px;

		@media(max-width: 991px) {
			height: 430px;
		}

		@media(max-width: 767px) {
			height: 650px;
		}

		@media(max-width: 576px) {
			height: 550px;
		}

		@media(max-width: 450px) {
			height: 490px;
		}

		@media(max-width: 400px) {
			height: 460px;
		}


		#similarsection1 {
			z-index: 3;
			visibility: visible;
		}

		#similarsection2 {

			visibility: hidden;
			z-index: 2;
		}

		#similarsection3 {
			visibility: hidden;
			z-index: 1;
		}
	}
}