/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Flama';
	src: url('RESOURCE/fonts/FlamaBook.eot');
	src: url('RESOURCE/fonts/FlamaBook.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FlamaBook.woff2') format('woff2'),
		url('RESOURCE/fonts/FlamaBook.woff') format('woff'),
		url('RESOURCE/fonts/FlamaBook.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Flama';
	src: url('RESOURCE/fonts/FlamaLight.eot');
	src: url('RESOURCE/fonts/FlamaLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FlamaLight.woff2') format('woff2'),
		url('RESOURCE/fonts/FlamaLight.woff') format('woff'),
		url('RESOURCE/fonts/FlamaLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Flama';
	src: url('RESOURCE/fonts/Flama.eot');
	src: url('RESOURCE/fonts/Flama.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Flama.woff2') format('woff2'),
		url('RESOURCE/fonts/Flama.woff') format('woff'),
		url('RESOURCE/fonts/Flama.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Romie';
	src: url('RESOURCE/fonts/Romie-MediumItalic.eot');
	src: url('RESOURCE/fonts/Romie-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Romie-MediumItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Romie-MediumItalic.woff') format('woff'),
		url('RESOURCE/fonts/Romie-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-SemiBold.eot');
	src: url('RESOURCE/fonts/Silka-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Silka-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Medium.eot');
	src: url('RESOURCE/fonts/Silka-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Regular.eot');
	src: url('RESOURCE/fonts/Silka-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Bold.eot');
	src: url('RESOURCE/fonts/Silka-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-Light.eot');
	src: url('RESOURCE/fonts/SilkaMono-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-Light.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-SemiBold.eot');
	src: url('RESOURCE/fonts/SilkaMono-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-MediumItalic.eot');
	src: url('RESOURCE/fonts/SilkaMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-MediumItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-MediumItalic.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-Medium.eot');
	src: url('RESOURCE/fonts/SilkaMono-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-Medium.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-Bold.eot');
	src: url('RESOURCE/fonts/SilkaMono-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka Mono';
	src: url('RESOURCE/fonts/SilkaMono-Regular.eot');
	src: url('RESOURCE/fonts/SilkaMono-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SilkaMono-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SilkaMono-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SilkaMono-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Black.eot');
	src: url('RESOURCE/fonts/Silka-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Black.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-ThinItalic.eot');
	src: url('RESOURCE/fonts/Silka-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-ThinItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-ThinItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-BoldItalic.eot');
	src: url('RESOURCE/fonts/Silka-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-BoldItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-BoldItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Bold.eot');
	src: url('RESOURCE/fonts/Silka-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka ';
	src: url('RESOURCE/fonts/Silka-ExtraLightItalic.eot');
	src: url('RESOURCE/fonts/Silka-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-ExtraLightItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-ExtraLightItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Light.eot');
	src: url('RESOURCE/fonts/Silka-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Light.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-BlackItalic.eot');
	src: url('RESOURCE/fonts/Silka-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-BlackItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-BlackItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka ';
	src: url('RESOURCE/fonts/Silka-ExtraLight.eot');
	src: url('RESOURCE/fonts/Silka-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/Silka-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Medium.eot');
	src: url('RESOURCE/fonts/Silka-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-LightItalic.eot');
	src: url('RESOURCE/fonts/Silka-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-LightItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-LightItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-MediumItalic.eot');
	src: url('RESOURCE/fonts/Silka-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-MediumItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-MediumItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-RegularItalic.eot');
	src: url('RESOURCE/fonts/Silka-RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-RegularItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-RegularItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-RegularItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-SemiBold.eot');
	src: url('RESOURCE/fonts/Silka-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Silka-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Regular.eot');
	src: url('RESOURCE/fonts/Silka-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-SemiBoldItalic.eot');
	src: url('RESOURCE/fonts/Silka-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-SemiBoldItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-SemiBoldItalic.woff') format('woff'),
		url('RESOURCE/fonts/Silka-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Silka';
	src: url('RESOURCE/fonts/Silka-Thin.eot');
	src: url('RESOURCE/fonts/Silka-Thin.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Silka-Thin.woff2') format('woff2'),
		url('RESOURCE/fonts/Silka-Thin.woff') format('woff'),
		url('RESOURCE/fonts/Silka-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}