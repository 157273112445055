.static-view {
	margin-top: var(--page-margin-top);

	&.freizeittipps-v1 {
		.freizeittipps-content {
			font-size: 20px;
			line-height: 35px;
		}

		img {
			@media (max-width:992px) {
				width: 100%;
			}

			@media (max-width:767px) {
				width: auto;
				margin: 0 auto 30px;
				display: inherit;
			}

		}
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner1.webp);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 50vh;
		position: relative;

		&.ueber {
			background-image: url(RESOURCE/img/uber_banner.webp);
		}

		&.eigentermer {
			background-image: url(RESOURCE/img/eigentumer_banner.webp);
		}

		&.faq {
			background-image: url(RESOURCE/img/faq_banner_01.webp);
		}

		&.contact {
			background-image: url(RESOURCE/img/contact_banner01.webp);
		}
	}
}

.cta-bl {
	display: flex;
	align-items: center;

	p {
		margin: 0 0 0 15px;
	}
}